import "react-simple-toasts/dist/theme/success.css";
import "react-simple-toasts/dist/theme/failure.css";

import axios from "axios";
import React, { FC, FormEvent } from "react";
import toast, { toastConfig } from "react-simple-toasts";

import Button from "../Button";
import Input from "../Input";

interface FormProps { }

toastConfig({ 
  position: "top-center", 
  clickClosable: true, 
  duration: 5000, 
  zIndex: 500,
  offsetY: 90, 
});

const Form: FC<FormProps> = () => {
  const submitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formEl = e.target as HTMLFormElement;
    const form = new FormData(formEl);
    const formData = Object.fromEntries(form);

    try {
      const response = await axios(`${process.env.REACT_APP_API_URL}/send-email`, {
        method: "POST",
        data: formData,
      });

      if (response.data?.message) {
        toast(response.data.message, { theme: "success" });
      }
      
      formEl.reset();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        toast(err.response.data?.message || "Something went wrong.", { theme: "failure" });
    }
  };

  return (
    <form className="form" name="form1" onSubmit={submitForm}>
      <fieldset className="form__content">
        <div className="form__flex-container">
          <div className="form__form-item">
            <label className="form__label" htmlFor="firstName">First Name</label>
            <Input type="text" id="firstName" name="firstName" placeholder="Your First Name" required />
          </div>

          <div className="form__form-item">
            <label className="form__label" htmlFor="lastName">Last Name</label>
            <Input type="text" id="lastName" name="lastName" placeholder="Your Last Name" required />
          </div>

          <div className="form__form-item">
            <label className="form__label" htmlFor="email">Email</label>
            <Input type="email" id="email" name="email" placeholder="your-email@example.com" required />
          </div>

          <div className="form__form-item">
            <label className="form__label" htmlFor="phone">Phone Number</label>
            <Input type="numeric" pattern="\+?[0-9\s]{7,15}" id="phone" name="phone" placeholder="+36 1 234 5678" />
          </div>

          <div className="form__form-item">
            <label className="form__label" htmlFor="message">Your Message</label>
            <textarea
              className="text-input form__text-input" 
              id="message" 
              name="message" 
              placeholder="Your message" 
              rows={3} 
              required />
          </div>

          <Button className="form__btn" type="submit" text="Let's talk!" />

        </div>
      </fieldset>
    </form>

  );
};

export default Form;