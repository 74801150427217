import Link from "components/styleguide/Link";
import React from "react";

const Nav = () => {
  return (
    <nav className="header-menu" role="navigation">
      <ul className="header-menu__list" role="list">
        <li className="header-menu__list-item" role="list-item">
          <Link href="/#about" section={"about"} text="About" />
        </li>
        <li className="header-menu__list-item" role="list-item">
          <Link href="/#contacts" section={"contacts"} text="Contacts" />
        </li>
        <li className="header-menu__list-item" role="list-item">
          <Link href="/#services" text="Services" />
        </li>
        <li className="header-menu__list-item" role="list-item">
          <Link href="/privacy-policy" text="Privacy Policy" />
        </li>
      </ul>
    </nav>
  );
};

export default Nav;