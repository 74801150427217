import Footer from "components/Footer";
import Header from "components/Header";
import Main from "components/Main";
import React from "react";

const Home = () => {

  return (
    <div className="page">
      <Header />
      <Main />
      <Footer />
    </div>
  );
};

export default Home;