import Link from "components/styleguide/Link";
import React from "react";

const Links = () => (
  <div className="footer__flex-inner">
    <nav className="footer-menu" role="navigation">
      <ul className="footer-menu__list" role="list">
        <li className="footer-menu__list-item" role="list-item">
          <Link href="#about" section={"about"} text="About me" className="link--secondary"/>
        </li>
        <li className="footer-menu__list-item" role="list-item">
          <Link href="#form" section={"form"} text="Contact me" className="link--secondary" />
        </li>
        <li className="footer-menu__list-item" role="list-item">
          <Link href="/privacy-policy" text="Privacy" className="link--secondary" />
        </li>
      </ul>
    </nav>
  </div>
);

export default Links;