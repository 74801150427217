import React, { FC } from "react";

interface InputProps {
  type: string;
  id: string;
  name: string;
  placeholder: string;
  required?: boolean;
  pattern?: string;
}

const Input: FC<InputProps> = ({ type, id, name, placeholder, pattern, required = false }) => {
  return <input type={type} id={id} name={name} placeholder={placeholder} className="text-input form__text-input" required={required} pattern={pattern}/>;
};

export default Input;