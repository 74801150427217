import React from "react";

import AboutListItems from "./AboutListItems";

const About = () => {
  // const backgroundRef = useRef(null);

  // useEffect(() => {
  //   const element = backgroundRef.current;

  //   if (!element) {
  //     return;
  //   }

  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         const div = entry.target as HTMLDivElement;

  //         div.classList.remove("lazy");

  //         observer.unobserve(element);
  //       }
  //     });
  //   }, {
  //     threshold: 0,
  //   });

  //   observer.observe(element);

  //   return (): void => {
  //     observer.unobserve(element);
  //   };
  // }, []);

  return (
    <section id="about" className="about">
      <div className="about__content">
        <div className="about__flex-container">
          <h2 className="about__title">About me</h2>
          <AboutListItems />
        </div>
        
        <div className="about__img-container img-container"></div>
      </div>
    </section>

  );
};

export default About;