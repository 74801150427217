import React from "react";

import About from "./About";
import Hero from "./Hero";
import MainDemo from "./MainDemo";
import Registration from "./Registration";
import Services from "./Services";

const Main = () => {
  return (
    <>
      <Hero />
      <MainDemo />
      <About />
      <Services />
      <Registration />
    </>
  );
};

export default Main;