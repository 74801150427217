import "./scss/main.scss";

import Home from "pages/Home";
import PrivacyPolicy from "pages/PrivacyPolicy";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
};

export default App;
