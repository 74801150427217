import React, { useEffect, useRef } from "react";

const MainDemo = () => {
  // const backgroundRef = useRef(null);

  // useEffect(() => {
  //   const element = backgroundRef.current;

  //   if (!element) {
  //     return;
  //   }

  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         const div = entry.target as HTMLDivElement;

  //         div.classList.remove("lazy");

  //         observer.unobserve(element);
  //       }
  //     });
  //   }, {
  //     threshold: 0,
  //   });

  //   observer.observe(element);

  //   return (): void => {
  //     observer.unobserve(element);
  //   };
  // }, []);

  return (
    <section className="demo">
      <div className="demo__content">
        <div className="demo__text-container">
          <h2 className="demo__title">Success is no accident</h2>
          <p className="demo__description">
          Ever since humankind existed, mentors have been guiding leaders, a fact which can be confirmed in biographies but is also reflected in tales and myth. 
          Just consider the great Greek Goddess Athena, who mentored Odysseus, or Socrates, mentor of Plato, who eventually mentored Aristotle. 
          There are numerous examples of advisors appearing in times of challenge to share their wisdom and guide to ones success.
          </p>
          <p className="demo__description demo__description__bold">
          Great leaders reach tremendous success and make major accomplishments after heeding lessons of a mentor. Finding ones own “Athena” can help us all progress to where we’d like to be.
          </p>
        </div>
      </div>
    </section>
  );
};

export default MainDemo;