import EmailIcon from "assets/images/icon-email.svg";
import LocationIcon from "assets/images/icon-location.png";
import TelegramIcon from "assets/images/icon-telegram.svg";
import React from "react";

const Contacts = () => (
  <ul id="contacts" className="contact-list">
    <li className="contact-list__item">
      <div className="contact-list__flex-container">
        <img
          src={LocationIcon}
          alt="White location icon"
          title="Check the location"
          className="contact-list__image"
          style={{ height: "25px", width: "25px" }}
        />
        <p className="contact-list__text">
        Budapest, Hungary
        </p>
      </div>
    </li>
    <li className="contact-list__item">
      <div className="contact-list__flex-container">
        <img
          src={TelegramIcon}
          alt="Telegram"
          title="Telegram"
          className="contact-list__image"
        />
        <p className="contact-list__text">@anitaboda</p>
      </div>
    </li>
    <li className="contact-list__item">
      <div className="contact-list__flex-container">
        <img
          src={EmailIcon}
          alt="White Gmail logo"
          title="Contact me by email"
          className="contact-list__image"
        />
        <p className="contact-list__text">boda.ana@outlook.com</p>
      </div>
    </li>
  </ul>
);

export default Contacts;