import Nav from "components/Nav";
import Logo from "components/styleguide/Logo";

const Header = () => {
  return (
    <header className="header" role="heading">
      <div className="header__content" role="banner">
        <Logo />
        <Nav />
      </div>
    </header>
  );

};

export default Header; 