import React, { FC } from "react";

interface LinkProps {
  className?: string;
  href: string;
  text: string;
  section?: string;
}

const Link: FC<LinkProps> = ({ href, text, section, className = "" }) => {

  const scrollToSection = () => {
    if (!section) return;

    document.getElementById(section)?.scrollIntoView({ behavior: "smooth" });
  };
  
  return <a className={`link ${className}`} href={href} role="link" onClick={scrollToSection}>{text}</a>;
};

export default Link;