import anime from "animejs";
import Form from "components/styleguide/Form";
import React, { useEffect, useRef, useState } from "react";

const Registration = () => {
  const [isAnimationPlaying, setIsAnimationPlaying] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const element = sectionRef.current;

    if (!element) {
      return;
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsAnimationPlaying(true);

          observer.unobserve(element);
        }
      });
    }, {
      threshold: 0,
    });

    observer.observe(element);

    return (): void => {
      observer.unobserve(element);
    };
  }, []);

  useEffect(() => {
    if (isAnimationPlaying) {
      anime({ 
        targets: ".registration__img-background",
        translateX: [200, 0],
        easing: "easeInOutQuad",
        duration: 1500,
        from: "right",

        opacity: {
          value: [0, 1],
          duration: 1000,
          easing: "easeInOutQuad",
        }, 
      });
      
      anime({ targets: ".registration__form",
        translateY: [100, 0],
        easing: "easeInOutQuad",
        duration: 1500,
        from: "bottom",
        delay: 500,

        opacity: {
          value: [0, 1],
          duration: 1000,
          easing: "easeInOutQuad",
        }, 
      });
    }
  }, [isAnimationPlaying]);

  return <section ref={sectionRef} id="form" className="registration">
    <div className="registration__content">
      <div className="registration__img-background"></div>

      <div className="registration__form">
        <h2 className="registration__title">Embrace the wind of change!</h2>
        <p className="registration__text">
          {`Since my primary drive is the love for helping people and building success stories, 
        I am flexible to discuss conditions of the mentorship case by case.
        Send me a message and let's talk!`}
        </p>
    
        <Form />
      </div>
    </div>
  </section>;
};

export default Registration;