import Logo from "components/styleguide/Logo";
import React from "react";

import Contacts from "./Contacts";
import Links from "./Links";

const Footer = () => (
  <footer id={"contacts"} className="footer">
    <div className="footer__content">
      <Logo />
      <div className="footer__flex-outer">
        <Contacts />
        <Links />
      </div>

      <p className="footer__copyright">&copy; Copyright {new Date().getFullYear()}</p>
    </div>
  </footer>
);

export default Footer;