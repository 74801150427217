import IconConversation from "assets/images/icon-conversation-100.png";
import IconGoals from "assets/images/icon-goals-100.png";
import IconStrengths from "assets/images/icon-strength-100.png";
import IconCheerleader from "assets/images/icon1-cheerleader-100.png";
import React, { useEffect, useRef } from "react";

const Services = () => {
  // const backgroundRef = useRef(null);

  // useEffect(() => {
  //   const element = backgroundRef.current;

  //   if (!element) {
  //     return;
  //   }

  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         const div = entry.target as HTMLDivElement;

  //         div.classList.remove("lazy");

  //         observer.unobserve(element);
  //       }
  //     });
  //   }, {
  //     threshold: 0,
  //   });

  //   observer.observe(element);

  //   return (): void => {
  //     observer.unobserve(element);
  //   };
  // }, []);

  return (
    <section id="services" className="features">
      <div className="features__content">

        <div className="features__container">
          <h2 className="features__title">What I offer</h2>
          <div className="features__grid-container">
            <article className="feature">
              <img className="feature__icon" src={IconConversation} alt="Conversation icon" />
              <p className="feature__description">
              Regular conversations with a deep dive into the current situation and desired outcome.
              </p>
            </article>
            <article className="feature">
              <img className="feature__icon" src={IconGoals} alt="Conversation icon" />
              <p className="feature__description">
              Build clear goals and plan with actions to follow for the mentorship period and afterwards.
              </p>
            </article>
            <article className="feature">
              <img className="feature__icon" src={IconStrengths} alt="Conversation icon" />
              <p className="feature__description">
              Understanding your strengths and how to rely on them.
              </p>
            </article>
            <article className="feature">
              <img className="feature__icon" src={IconCheerleader} alt="Conversation icon" />
              <p className="feature__description">
              Your biggest cheerleader through change as I guide you in your own growth.
              </p>
            </article>
          </div>
        </div>
      </div>
    </section>

  );
};

export default Services;