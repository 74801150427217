
import anime from "animejs";
import Link from "components/styleguide/Link";
import React, { useEffect } from "react";

const Hero = () => {
  useEffect(() => {
    anime({
      targets: ".hero__text-container",
      delay: 500,
      translateY: [-100, 0],
      easing: "easeInOutQuad",
      duration: 1500,
      opacity: {
        value: [0, 1],
        duration: 1500,
        easing: "easeInOutQuad",
      },
    });

    anime({
      targets: ".hero__img-background",
      translateX: [-300, 0],
      easing: "easeInOutQuad",
      duration: 1500,
      from: "left",

      opacity: {
        value: [0, 1],
        duration: 1000,
        easing: "easeInOutQuad",
      },
    });
  }, []);

  // useEffect(() => {
  //   const element = backgroundRef.current;

  //   if (!element) {
  //     return;
  //   }

  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         const div = entry.target as HTMLDivElement;

  //         div.classList.remove("lazy");

  //         observer.unobserve(element);
  //       }
  //     });
  //   }, {
  //     threshold: 0,
  //   });

  //   observer.observe(element);

  //   return (): void => {
  //     observer.unobserve(element);
  //   };
  // }, []);

  return (
    <section className="hero">
      <div className="hero__content">

        <div className="hero__text-container">
          <h2 className="hero__title">Hey there! I am Anita</h2>
          <p className="hero__description">
          A relationship manager at a large multinational company with passion to mentor and coach women who want to succeed in their transition from one environment to another (work, city, family, etc.).
          </p>
          <div className="hero__buttons">
            <Link href="#form" section={"form"} text="Get in touch" className="hero__link btn" />
            <Link href="#about" section={"about"} text="About me" className="hero__link btn btn-about"  />
          </div>
        </div>
        
        <div className="hero__img-background"></div>
      </div>
    </section>
  );
};

export default Hero;