import React, { FC } from "react";

interface LogoProps {
  className?: string;
  iconClassName?: string;
}

const Logo: FC<LogoProps> = () => {
  return (
    <a href="/" title="Go to homepage" role="link">
      <div className="logo">
        <h2 className="logo__item">
        Anita
        </h2>
        <h2 className="logo__item">
        Boda
        </h2>
        <h2 className="logo__item">
        Coaching
        </h2>
      </div>
    </a>
  );
};

export default Logo;