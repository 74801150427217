import PrivacyIcon from "assets/images/icons-privacy-50.png";
import Footer from "components/Footer";
import Header from "components/Header";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="page">
      <Header />
    
      <div className="privacy">
        <div className="privacy__header">
          <img className="privacy__icon" src={PrivacyIcon} alt={"Privacy icon"} />
          <h3 className="privacy__title">Privacy Policy</h3>
        </div>

        <div className="privacy__content">
          <p className="title">Data we collect</p>
          <p className="text">To send us a message, we require your First Name, Last Name and E-mail to identify you and to be able to come with a response. We <span>do not</span> share this data with any third-parties.</p>
          <p className="text">This data <span>is not</span> stored.</p>
          <p className="text">You can request to view or delete this data at any time by sending me a message at <span>boda.ana@outlook.com</span>.</p>
        </div>
      </div>

      <Footer />
    </div>);
};

export default PrivacyPolicy;