import CoachIcon from "assets/images/icons-coach-50.png";
import FamilyIcon from "assets/images/icons-family-50.png";
import LanguageIcon from "assets/images/icons-language-50.png";
import PointIcon from "assets/images/icons-point-50.png";
import SocietyIcon from "assets/images/icons-society-50.png";
import WorkIcon from "assets/images/icons-work-50.png";
import React from "react";

const items = [{
  icon: SocietyIcon,
  description: "Born in a small country, integrated in 4 different cultures from East to West Europe",
}, 
{
  icon: PointIcon,
  description: "Currently live in Budapest, Hungary",
},
{
  icon: FamilyIcon,
  description: "Mother of a lovely daughter, wife to a proud and talented Hungarian, dog owner",
},
{
  icon: LanguageIcon,
  description: "Speak multiple languages (4+)",
},
{
  icon: WorkIcon,
  description: "Changed jobs and grew professionally alongside motherhood",
},
{
  icon: CoachIcon,
  description: "Mentored and coached occasionally. Enrolling in a Coaching training programme to gain professional experience",
}];

const AboutListItems = () => {
  return (
    <div className="about__list">
      {items.map(({ icon, description }, index) => (
        <div key={index} className="about__list-item">
          <img className="about__list-item__icon" src={icon}/>
          <p className="about__list-item__description">{description}</p>
        </div>
      ))}
    </div>
  );
};

export default AboutListItems;